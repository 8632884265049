import React, { useState, useEffect, useRef } from 'react';
import Particles from './Particles';
import './content.css';
import mines from './mines.png'; 
import avatar from './avatar.jpg'; 
import star from './star.png'; 

const Content = ({ activeSection, onBack, setIsGameOpen, isGameOpen }) => {
  const [counter, setCounter] = useState(3);
  const [grid, setGrid] = useState(Array(25).fill(null)); 
  const [starIndices, setStarIndices] = useState([]); 
  const [cooldown, setCooldown] = useState(false); 
  const [notification, setNotification] = useState(''); 
  const contentRef = useRef(null);  // Ref для получения размера контейнера
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Обновляем размеры контейнера после монтирования компонента
  useEffect(() => {
    if (contentRef.current) {
      setDimensions({
        width: contentRef.current.offsetWidth,
        height: contentRef.current.offsetHeight,
      });
    }
  }, []);

  const handleOpenGame = () => {
    setIsGameOpen(true); 
    generateGrid(); 
  };

  const handleBack = () => {
    setIsGameOpen(false); 
    setStarIndices([]); 
    onBack(); 
  };

  const handleIncrement = () => {
    if (counter < 7) {
      setCounter(counter + (counter === 1 ? 2 : 2));
    }
  };

  const handleDecrement = () => {
    if (counter > 1) {
      setCounter(counter - (counter === 3 ? 2 : 2));
    }
  };

  const handleGenerate = () => {
    if (cooldown) {
        setNotification('Пожалуйста, подождите 5 секунд перед следующим нажатием.');
        return;
    }

    setCooldown(true); 
    setNotification('Генерация следущих звезд...'); 

    const starCount = getStarCount(counter); 
    const newStarIndices = generateStarIndices(starCount); 
    const newGrid = grid.map((cell, index) => ({
        ...cell,
        hasStar: newStarIndices.includes(index),
    }));

    setGrid(newGrid); 
    setStarIndices(newStarIndices); 

    setTimeout(() => {
        setCooldown(false); 
        setNotification(''); 
    }, 5000);
  };

  const getStarCount = (count) => {
    switch (count) {
      case 1:
        return 10;
      case 3:
        return 5;
      case 5:
        return 4;
      case 7:
        return 3;
      default:
        return 0;
    }
  };

  const generateStarIndices = (count) => {
    const positions = new Set();
    while (positions.size < count) {
      const randomIndex = Math.floor(Math.random() * 25);
      positions.add(randomIndex);
    }
    return Array.from(positions);
  };

  const generateGrid = () => {
    const newGrid = Array(25).fill(null).map((_, index) => ({
      index,
      hasStar: false,
    }));
    setGrid(newGrid);
  };

  const refreshGridWithStars = () => {
    const newStarIndices = generateStarIndices(getStarCount(counter));
    const newGrid = grid.map((cell, index) => ({
      ...cell,
      hasStar: newStarIndices.includes(index),
    }));
    setGrid(newGrid);
    setStarIndices(newStarIndices);
  };

  return (
    <div className="Content_div" ref={contentRef}>
      {/* Включаем анимацию частиц только в области контента */}
      <Particles width={dimensions.width} height={dimensions.height} />

      {/* Notification Message */}
      {notification && (
        <div className={`notification visible`}>
          {notification}
        </div>
      )}

      {/* Выбор игры */}
      {activeSection === 'game' && !isGameOpen && (
        <>
          <img src={mines} alt="Mines" className="game-image" />
          <h1>MINES</h1>
          <button className="open-button" onClick={handleOpenGame}>
            ОТКРЫТЬ
          </button>
        </>
      )}

      {/* Игра запущена */}
      {isGameOpen && (
        <>
          {/* Поле для плиток 5x5 */}
          <div className="game-grid">
            {grid.map((cell) => (
              <div key={cell.index} className="grid-cell">
                {cell.hasStar && (
                  <img src={star} alt="Star" className="star" />
                )}
              </div>
            ))}
          </div>

          {/* Панель с кнопками для счетчика */}
          <div className="counter-panel">
            <button className="counter-button" onClick={handleDecrement}>-</button>
            <span className="counter">{counter}</span>
            <button className="counter-button" onClick={handleIncrement}>+</button>
            <button 
              className="generate-button" 
              onClick={() => { handleGenerate(); refreshGridWithStars(); }} 
              disabled={cooldown} // Disable button if cooldown is active
            >
              СГЕНЕРИРОВАТЬ
            </button>
          </div>

          <button className="back-button" onClick={handleBack}>НАЗАД</button>
        </>
      )}

      {/* TG раздел */}
      {activeSection === 'section2' && (
        <>
          <img src={avatar} alt="Avatar" className="avatar" />
          <h1>TOP WORK</h1>
          <a className="tg-button" href="https://t.me/+VYVW9qGUEgxiMWVi" target="_blank" rel="noopener noreferrer">
            ПЕРЕЙТИ В КАНАЛ
          </a>
        </>
      )}
    </div>
  );
};

export default Content;
