import "./head.css";
import myImage from './avatar.jpg';

const Head = () => {
  return (
    <header>
      <div className="Box">
        <img src={myImage} alt="Avatar" />
        <div className="Name_team">
          <h1>TOP WORK</h1>
        </div>
      </div>
    </header>
  );
};

export default Head;
