import React from 'react';
import './footer.css';
import gameIcon from './game.png'; 
import tgIcon from './tg.png'; 

const Footer = ({ onGameClick, onTg, isGameOpen }) => {
  return (
    <div className="Contener_footer">
      {!isGameOpen && ( // Иконки отображаются только когда игра не запущена
        <>
          <a 
            href="#section1" 
            className="icon-link" 
            onClick={onGameClick} // Переход к выбору игры
          >
            <img src={gameIcon} alt="Game" className="footer-icon" /> 
          </a>
          <a 
            href="#section2" 
            className="icon-link" 
            onClick={onTg} // Переход в TG раздел
          >
            <img src={tgIcon} alt="TG" className="footer-icon" /> 
          </a>
        </>
      )}
    </div>
  );
};

export default Footer;
