import React, { useState } from 'react';
import './App.css';
import Head from './elements/head';
import Content from './elements/content';
import Footer from './elements/footer';

const App = () => {
  const [activeSection, setActiveSection] = useState('game'); // По умолчанию выбор игры
  const [isGameOpen, setIsGameOpen] = useState(false); // Состояние игры

  const handleGameClick = () => {
    setActiveSection('game'); // Переключение на выбор игры
  };

  const handleTg = () => {
    setActiveSection('section2'); // Переключение на раздел TG
  };
  

  const handleBack = () => {
    setActiveSection('game'); // Возврат к выбору игры
    setIsGameOpen(false); // Закрытие игры
  };

  return (
    <div className='Global_div'>
      <header>
        <Head />
      </header>
      <Content 
        activeSection={activeSection} 
        onBack={handleBack} 
        setIsGameOpen={setIsGameOpen} 
        isGameOpen={isGameOpen} // Передаем состояние игры
      />
      <footer>
        <Footer 
          onGameClick={handleGameClick} 
          onTg={handleTg} 
          isGameOpen={isGameOpen} // Передаем состояние игры для скрытия иконок
        />
      </footer>
    </div>
  );
};

export default App;
